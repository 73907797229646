<template>
  <a v-if="!!href" role="button"
    v-bind="$attrs"
    :href="href"
    :class="[pill ? 'initial:px-2 initial:py-2' : 'initial:px-8 initial:py-1' , big ? 'initial:py-3' : '', computedClassList]"
    class="py-1 font-semibold no-underline rounded-full text-base lg:text-lg"
  >
    <slot />
  </a>
  <button v-else
    v-bind="$attrs"
    :class="[pill ? 'px-2 py-2' : 'px-8 py-1' , computedClassList]"
    class="font-semibold h-[36px] rounded-full text-base lg:text-lg"
  >
    <slot />
  </button>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  variant: {
    type: String,
    default: 'primary'
  },
  href: {
    type: String,
    required: false
  },
  pill: {
    type: Boolean,
    default: false
  },
  big: {
    type: Boolean,
    default: false
  }
})

const mapClassList = {
  'primary' :'main-btn-primary',
  'outline-primary': 'main-btn-outline-primary',
  'inverted-primary': 'main-btn-inverted-primary',
  'light': 'main-btn-light',
  'transparent-light': 'main-btn-transparent-light',
  'transparent-mid': 'main-btn-transparent-mid',
  'transparent-dark': 'main-btn-transparent-dark'
}
const computedClassList = computed(() =>{
  return mapClassList[props.variant]
})
</script>