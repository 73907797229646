import { defineStore } from 'pinia'
import articlesQuery from '@/apollo/queries/article/articles.gql'
import tagsQuery from '@/apollo/queries/tag/tags.gql'

const INTERVAL = 9

export const useStore = defineStore({
  id: 'blog-mnet-store',
  state: () => {
    return {
      articles: [],
      tags: [],
      limit: INTERVAL
    }
  },
  getters: {
    highlightedArticles () {
      return this.articles.filter(a => a.attributes.highlight)
    },
    restOfArticles () {
      return this.articles.filter(a => !a.attributes.highlight)
    },
    articleBySlug: (state) => {
      return (slug) => state.articles?.find(a => a.attributes.slug === slug)
    },
    tagBySlug: (state) => {
      return (slug) => state.tags?.find(t => t.attributes.slug === slug )
    }
  },
  actions: {
    incrementLimit () {
      this.limit += INTERVAL
    },
    async fetchArticles () {
      const { data } = await useAsyncQuery(articlesQuery)
      this.articles = data.value?.mnetArticles?.data
    },
    async fetchTags () {
      const { data } = await useAsyncQuery(tagsQuery)
      this.tags = data.value?.mnetTags?.data.filter(tag => tag.attributes.mnet_articles.data.length > 0)
    }
  }
})